export function query_getAllData() {
	return {
		query: {
			match_all: {}
		},
		sort: [
			{
				datetime_Observed: {
					order: 'desc'
				}
			}
		],
		size: 10000
	};
}

export function query_getAllDataFreus() {
	return {
		query: {
			match_all: {}
		},
		sort: [
			{
				fechaEscala_timestamp: {
					order: 'desc'
				}
			}
		],
		size: 5000
	};
}

export function query_byDateRangeFreus(startDate, endDate = Date.now()) {
	return {
		query: {
			bool: {
				must: [
					{
						range: {
							fechaEscala_timestamp: {
								gte: startDate,
								lte: endDate
							}
						}
					}
				]
			}
		},
		size: 10000,
		sort: [
			{
				fechaEscala_timestamp: {
					order: 'desc'
				}
			}
		]
	};
}

export function query_LastData(device) {
	return {
		query: {
			bool: {
				must: [
					{
						match_phrase: {
							device: device
						}
					}
				]
			}
		},
		size: 1,
		sort: [
			{
				'@timestamp': {
					order: 'desc'
				}
			}
		]
	};
}
export function query_LastDataHistoric(device) {
	return {
		query: {
			bool: {
				must: [
					{
						match_phrase: {
							device: device
						}
					}
				]
			}
		},
		size: 1
	};
}

export function duisport_query(deviceAcronym) {
	return {
		query: {
			bool: {
				must: [
					{
						match_phrase: {
							device_acronym: deviceAcronym
						}
					}
				]
			}
		},
		size: 1,
		sort: [
			{
				dateObserved_timestamp: {
					order: 'desc'
				}
			}
		]
	};
}

/* Format for dates:
	"gte": "2020-01-01T00:30:00.000Z",
	"lte": "2021-10-12T23:59:00.000Z"
*/
export function query_filterByInterval(device, startDate, endDate) {
	return {
		query: {
			bool: {
				must: [
					{
						match_phrase: {
							device: device
						}
					},
					{
						range: {
							dateObserved_timestamp: {
								gte: startDate,
								lte: endDate
							}
						}
					}
				]
			}
		},
		size: 10000,
		sort: [
			{
				dateObserved_timestamp: {
					order: 'asc'
				}
			}
		]
	};
}

export function query_filterParameterByInterval(device, parameter, startDate, endDate) {
	return {
		query: {
			bool: {
				must: [
					{
						match_phrase: {
							device: device
						}
					},
					{
						exists: {
							field: parameter
						}
					},
					{
						range: {
							dateObserved_timestamp: {
								gte: startDate,
								lte: endDate
							}
						}
					}
				]
			}
		},
		size: 10000,
		sort: [
			{
				dateObserved_timestamp: {
					order: 'asc'
				}
			}
		]
	};
}

export function query_predictions(device, startDate, endDate) {
	return {
		query: {
			bool: {
				must: [
					{
						match_phrase: {
							'sensor-id': device
						}
					},
					{
						range: {
							dateObserved_timestamp: {
								gte: startDate,
								lte: endDate
							}
						}
					}
				]
			}
		},
		size: 10000
	};
}
//-------------------------------
/*
-- Format of gte --> "gte": 1610020800000
*/
export function query_filterLastTime(parameter, startDate) {
	return {
		query: {
			bool: {
				must: [
					{
						match_phrase: {
							measurement: parameter
						}
					},
					{
						range: {
							dateObserved_timestamp: {
								gte: startDate
							}
						}
					}
				]
			}
		}
	};
}

export function query_LastDataByParameter(device, elasticAcronym) {
	return {
		query: {
			bool: {
				must: [
					{
						match_phrase: {
							device: device
						}
					},
					{
						exists: {
							field: elasticAcronym
						}
					}
				]
			}
		},
		size: '1',
		sort: [
			{
				dateObserved_timestamp: {
					order: 'desc'
				}
			}
		]
	};
}

export function query_FirstDataByParameter(device, elasticAcronym) {
	return {
		query: {
			bool: {
				must: [
					{
						match_phrase: {
							device: device
						}
					},
					{
						exists: {
							field: elasticAcronym
						}
					}
				]
			}
		},
		size: '1',
		sort: [
			{
				dateObserved_timestamp: {
					order: 'asc'
				}
			}
		]
	};
}

export function query_FirstDataByDevice(device) {
	return {
		query: {
			bool: {
				must: [
					{
						match_phrase: {
							device: device
						}
					}
				]
			}
		},
		size: '1',
		sort: [
			{
				dateObserved_timestamp: {
					order: 'asc'
				}
			}
		]
	};
}

export function query_HistoricDataByParameter(device, elasticAcronym, startTimestamp, endTimestamp) {
	return {
		query: {
			bool: {
				must: [
					{
						match_phrase: {
							device: device
						}
					},
					{
						exists: {
							field: elasticAcronym
						}
					},
					{
						range: {
							dateObserved_timestamp: {
								gte: startTimestamp,
								lte: endTimestamp
							}
						}
					}
				]
			}
		},
		aggs: {
			['max_' + elasticAcronym]: {
				max: {
					field: elasticAcronym
				}
			},
			['min_' + elasticAcronym]: {
				min: {
					field: elasticAcronym
				}
			}
		},
		/* size: '10000', */
		sort: [
			{
				dateObserved_timestamp: {
					order: 'asc'
				}
			}
		]
	};
}

export function query_AdvancedDataByParameter(devices, elasticAcronym, startTimestamp, endTimestamp) {
	return {
		query: {
			bool: {
				must: [
					{
						terms: {
							'device.keyword': devices
						}
					},
					{
						exists: {
							field: elasticAcronym
						}
					},
					{
						range: {
							dateObserved_timestamp: {
								gte: startTimestamp,
								lte: endTimestamp
							}
						}
					}
				]
			}
		},
		aggs: {
			['max_' + elasticAcronym]: {
				max: {
					field: elasticAcronym
				}
			},
			['min_' + elasticAcronym]: {
				min: {
					field: elasticAcronym
				}
			}
		},
		size: '10000',
		sort: [
			{
				dateObserved_timestamp: {
					order: 'asc'
				}
			}
		]
	};
}

export function query_LastDataDataByParameterArray(device, existsFieldArray) {
	return {
		query: {
			bool: {
				must: [
					{
						bool: {
							should: existsFieldArray
						}
					},
					{
						match_phrase: {
							device: device
						}
					}
				]
			}
		},
		sort: [
			{
				dateObserved_timestamp: {
					order: 'desc'
				}
			}
		],
		size: 1
	};
}

// eslint-disable-next-line no-unused-vars
export function query_CompareAQIDataByParameter(device, elasticAcronym, startTimestamp, endTimestamp) {
	return {
		query: {
			bool: {
				must: [
					{
						match_phrase: {
							device: device
						}
					},
					{
						exists: {
							field: elasticAcronym
						}
					},
					{
						range: {
							dateObserved_timestamp: {
								gte: startTimestamp,
								lte: endTimestamp
							}
						}
					}
				]
			}
		},
		size: 1,
		sort: [
			{
				[elasticAcronym]: {
					order: 'desc'
				}
			}
		]
	};
}

export function query_LatestByFieldValue(fieldName, size = null, order = null) {
	return {
		_source: false,
		query: {
			match_all: {}
		},
		collapse: {
			field: fieldName,
			inner_hits: [
				{
					name: 'latest',
					size: size ?? 1,
					sort: [
						{
							'@timestamp': {
								order: order ?? 'desc'
							}
						}
					]
				}
			]
		}
	};
}

export function query_Document(documentId) {
	return {
		query: {
			bool: {
				filter: {
					term: {
						_id: documentId
					}
				}
			}
		}
	};
}

export function query_AQI(device) {
	return {
		size: 1,
		query: {
			bool: {
				must: [
					{
						range: {
							'@timestamp': {
								gte: 'now-1h',
								lte: 'now'
							}
						}
					},
					{
						match_phrase: {
							device: device
						}
					}
				]
			}
		},
		aggs: {
			max_o3: {
				max: {
					field: 'o3'
				}
			},
			max_no2: {
				max: {
					field: 'no2'
				}
			},
			max_pm25: {
				max: {
					field: 'pm25'
				}
			},
			max_pm10: {
				max: {
					field: 'pm10'
				}
			},
			max_so2: {
				max: {
					field: 'so2'
				}
			}
		}
	};
}

export function query_CAQI(device) {
	return {
		size: 1,
		query: {
			bool: {
				must: [
					{
						range: {
							'@timestamp': {
								gte: 'now-1h',
								lte: 'now'
							}
						}
					},
					{
						match_phrase: {
							device: device
						}
					}
				]
			}
		},
		aggs: {
			max_o3: {
				max: {
					field: 'o3'
				}
			},
			max_no2: {
				max: {
					field: 'no2'
				}
			},
			max_pm10: {
				max: {
					field: 'pm10'
				}
			}
		}
	};
}

export function query_errorTypes(exists, start_date, end_date) {
	return {
		query: {
			bool: {
				must: [
					{
						exists: {
							field: exists
						}
					},
					{
						range: {
							dateObserved_timestamp: {
								gte: start_date,
								lte: end_date
							}
						}
					}
				]
			}
		},
		size: 10000
	};
}
